<template>
  <div>
    <cui-forgot-password class=" mb-3 mt-5 pt-3" />
  </div>
</template>
<script>
import CuiForgotPassword from '@/components/cleanui/system/Auth/ForgotPassword'
export default {
  components: {
    CuiForgotPassword,
  },
}
</script>
