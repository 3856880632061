<template>
  <div :class="$style.containerForm">
    <div class="card" :class="$style.cardSection">
      <div class="font-size-24 mb-4 text-center" :class="$style.colorPrimary">
        <strong> {{ $t('authForm.resetPass') }} </strong>
      </div>
      <div v-if="!isSuccessReset">
        <a-form class="mb-4" :form="form" @submit="handleSubmit">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.emailPhone') }}</label>
            <a-input size="large" :placeholder="$t('form.placeholder_email_phone')"
              v-decorator="['user_login', { rules: [{ required: true, message: $t('authForm.requiredEmail') }] }]" />
          </a-form-item>
          <div>
            <a-button htmlType="submit" size="large" class="btn-submit text-center w-100" :loading="loading">
              <strong> {{ $t('authForm.resetMyPass') }} </strong>
            </a-button>
          </div>
        </a-form>
      </div>
      <div v-if="isSuccessReset">
        <a-alert :message="msgAlert.message" :description="msgAlert.description" type="success" show-icon closable
          :after-close="() => isSuccessReset = false" />
      </div>
      <router-link to="/auth/login" class="txt-forgot kit__utils__link font-size-16" :class="$style.colorPrimary">
        {{ $t('authForm.goToSignIn') }}
      </router-link>
    </div>
    <a-modal v-model="modalOTP" :footer="null" :centered="true" :closable="false" :maskClosable="false">
      <div :class="$style.modalOtp">
        <img :src="urlImage" class="logo" :class="$style.logoVendorOtp" />
        <h5 :class="$style.titleInfoOtp">
          {{ verifyType === 'phone' ? $t('verify.otpSubTitlePhone') : $t('verify.otpSubTitleEmail')}}
        </h5>
        <div class="mt-2">
          <div class="mb-2 mt-5">
            <div class="d-flex justify-content-center">
              <v-otp-input ref="otpInput" :input-classes="$style.otpInput" separator="" :num-inputs="6"
                :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChangeOTP" />
            </div>
          </div>
          <a-button type="primary" size="large" class="text-center btn-verify" @click.prevent="handleVerify"
            :disabled="otp.length < 6">
            <strong>{{ $t('verify.otpButton') }}</strong>
          </a-button>

          <p :class="$style.subtitleOtp">
            {{ $t('verify.otpWaitingText') }}
          </p>
          <p :class="$style.timerOTP">
            {{ time }}
          </p>
          <a-button type="link" :disabled="countDown > 0" @click.prevent="handleResend">
            {{ $t('verify.otpResend') }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CuiForgotPassword',
  computed: {
    ...mapState(['settings', 'app']),
    ...mapGetters({ loginStatusCode: 'user/loginStatusCode' }),
    loading() {
      return this.$store.state.user.loading
    },
    isVendorKino() {
      return this.$store.state.app.vendor_name && this.$store.state.app.vendor_name.toLowerCase() === 'kino'
    },
    urlImage() {
      return this.isVendorKino ? 'https://ik.imagekit.io/powerbiz/img/kino/master_copy.png?updatedAt=1640933165040' : this.$store.state.app.logo
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      msgAlert: {
        message: '',
        description: '',
      },
      userId: '',
      isSuccessReset: false,
      modalOTP: false,
      verifyType: 'phone',
      countDown: 300,
      time: '',
      otp: '',
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) return

        this.$store.dispatch('user/SENDEMAILCHANGEPASSWORD', {
          user_login: values.user_login,
        })
          .then((response) => {
            const { verification_method, user_login, user_id } = response.data
            this.modalOTP = true
            this.verifyType = verification_method.toLowerCase()
            this.userId = user_id

            this.$notification.success({
              message: this.$t('authForm.resetPasswordRequestSuccess', { type: verification_method.toLowerCase(), payload: user_login }),
            })
            const payload = {
              verifyType: this.verifyType,
              user_id: this.userId,
              type: 'RESET_PASSWORD',
            }
            this.$store.dispatch('user/SENTOTP', payload)
              .then(() => {
                this.countDown = 300
              })
              .catch(err => {
                this.$notification.error({
                  message: this.$t('authForm.registerFailed'),
                  description: err.response?.data?.message || err.message || 'Internval Server Error',
                })
              })
          })
          .catch((err) => {
            const { data } = err.response
            this.$notification.error({
              message: data && data.message ? data.message : 'Internal Server Error',
            })
          })
      })
    },
    handleVerify() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.userId,
        otp: this.otp,
      }
      this.$store.dispatch('user/VERIFYOTP', payload)
        .then(({ link, token }) => {
          this.$router.push(`/reset-password/${link}/${token}`)
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleResend() {
      const payload = {
        verifyType: this.verifyType,
        user_id: this.userId,
        type: 'RESET_PASSWORD',
      }
      this.$store.dispatch('user/SENTOTP', payload)
        .then(() => {
          this.countDown = 300
        })
        .catch(err => {
          this.$notification.error({
            message: this.$t('authForm.registerFailed'),
            description: err.response?.data?.message || err.message || 'Internval Server Error',
          })
        })
    },
    handleGetQueryParam() {
      const { userId } = this.$route.query
      this.userId = userId
    },
    handleSwitch(verifyType) {
      this.verifyType = verifyType
    },
    handleOnChangeOTP(value) {
      this.otp = value
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1

          let minutes = Math.floor(this.countDown / 60)
          minutes = minutes < 10 ? `0${minutes}` : minutes
          let seconds = this.countDown - minutes * 60
          seconds = seconds < 10 ? `0${seconds}` : seconds
          this.time = `${minutes} : ${seconds}`

          this.countDownTimer()
        }, 1000)
      }
    },
  },
  created: function () {
    this.countDownTimer()
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
<style lang="scss" scoped>
.logo {
  max-height: 75px;
  max-width: 200px;
}

.divider {
  max-width: 500px;
  margin: auto;
}

.btn-submit {
  background-color: var(--biz-brand-button);
  color: #ffffff;
  border-color: var(--biz-brand-button);
  font-size: 14px;

  &:hover {
    border-color: var(--biz-brand-button);
    color: var(--biz-brand-button);
  }

  @media (min-width: 700px) {
    font-size: 18px;
  }
}

.txt-forgot {
  font-size: 14px;

  @media (min-width: 700px) {
    font-size: 16px;
  }
}

.container-txt {
  display: flex;
  justify-content: space-between;
}
</style>
